import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { sortableHandle } from 'react-sortable-hoc';
import ReactSVG from 'react-svg';

import moment from 'moment';
import PolyUtil from 'polyline-encoded';

import CommunicationRequest from '@components/general/CommunicationRequest';
import UpdateActionTimesContainer from '@containers/action/crud/UpdateActionTimesContainer';
import ConsignmentDetailContainer from '@containers/consignment/ConsignmentDetailContainer';
import GoodsContainer from '@containers/goods/GoodsContainer';
import TransportEquipmentContainer from '@containers/goods/TransportEquipmentContainer';
import LocationContainer from '@containers/location/LocationContainer';

import FilePreview from '@uicomponents/FilePreview';
import FileViewer from '@uicomponents/FileViewer';
import MapPeek from '@uicomponents/MapPeek';
import NoData from '@uicomponents/NoData';
import OptionsMenu from '@uicomponents/OptionsMenu';
import SmallAttachments from '@uicomponents/SmallAttachments';
import Tag from '@uicomponents/Tag';

import Action from '@models/action/Action';
import Association from '@models/general/Association';

import { momentToDuration } from '@utils/dateUtils';
import { defaultDateTimeFormat } from '@utils/dateUtils';
import { administrativeReferenceToString } from '@utils/locationUtils';
import { truncate } from '@utils/stringUtils';

import ActionCards from './ActionCards';

const DragHandle = sortableHandle(() => (
  <div className="draggable">
    <ReactSVG src="/icons/drag.svg" />
  </div>
));

class ActionCard extends Component {
  state = {
    isUpdatingTripAction: false,
    expandedCommunicationRequests: false,
    files: [],
    filePreviewOpen: false,
    focussedFile: null,

    uploadedFiles: this.props.action
      ? this.props.action.documents?.map((document) => {
          return document.entity.file;
        })
      : [],
  };

  getLocationName = (location) => {
    return administrativeReferenceToString(location.administrativeReference || {}, true);
  };

  render() {
    const {
      isUpdatingTripAction,
      expandedCommunicationRequests,
      files,
      filePreviewOpen,
      focussedFile,
    } = this.state;
    const {
      t,
      depth,
      action,
      actions,
      moveActions,
      active,
      trip,
      updateTripAction,
      updateTripActionLifeCycle,
      updateTripActionTimes,
      addToStack,
      isDraggable,
      showConsignment,
      index,
      isGrouped,
      isNextGrouped,
    } = this.props;
    const actionIndex = index;
    const sortedActions = [...actions].sort((a, b) => a.entity.sequenceNr - b.entity.sequenceNr);
    const sortedSubActions = [...action.actions].sort(
      (a, b) => (a.entity?.sequenceNr || 0) - (b.entity?.sequenceNr || 0)
    );

    const previousMoveAction = moveActions
      ? moveActions.find(
          (moveAction) =>
            moveAction?.entity?.to &&
            action.location &&
            moveAction.entity.to.entity.id === action.location.entity.id &&
            (moveAction.entity.sequenceNr === index + 1 || moveAction.entity.sequenceNr === index)
        )?.entity
      : null;

    const startDateTimeConstraint = action.constraints
      .map((association) => association.entity)
      .find((constraint) => constraint.value.type === 'startDateTimeConstraint');
    const endDateTimeConstraint = action.constraints
      .map((association) => association.entity)
      .find((constraint) => constraint.value.type === 'endDateTimeConstraint');

    const options = [];

    if (trip) {
      if (depth > 0) {
        if (
          action.lifeCycle === 'planned' ||
          action.lifeCycle === 'projected' ||
          action.lifeCycle === 'actual'
        ) {
          const sortedParentActions = [...trip.actions].sort(
            (a, b) => a.entity.sequenceNr - b.entity.sequenceNr
          );
          const parentActionIndex = sortedParentActions.findIndex((pa) =>
            pa.entity.actions.map((a) => a.entity.id).includes(action.id)
          );

          // checking if the parent action has been realized (aka finished)
          if (
            trip.status === 'in_transit' &&
            (parentActionIndex === 0 ||
              sortedParentActions[parentActionIndex - 1].entity.lifeCycle === 'realized')
          ) {
            // check if the previous action is finished or index is 0
            if (
              actionIndex === 0 ||
              sortedActions[actionIndex - 1]?.entity.lifeCycle === 'realized'
            ) {
              if (
                (action.lifeCycle === 'planned' || action.lifeCycle === 'projected') &&
                !isUpdatingTripAction
              ) {
                options.push({
                  name: t('action.actualize'),
                  onClick: (e) => {
                    e.preventDefault();

                    trip && action && updateTripActionLifeCycle(trip, action, 'actual');
                  },
                });
              }

              if (action.lifeCycle === 'actual' && !isUpdatingTripAction) {
                options.push({
                  name: t('action.realize'),
                  onClick: (e) => {
                    e.preventDefault();

                    trip && action && updateTripActionLifeCycle(trip, action, 'realized');
                  },
                });
              }
            }
          }
        } else if (action.lifeCycle === 'realized') {
          options.push({
            name: t('action.editTimes'),
            onClick: (e) => {
              e.preventDefault();

              addToStack({
                name: t('action.editTimes'),
                component: (
                  <UpdateActionTimesContainer
                    action={action}
                    onSubmit={(actionId, startTime, endTime) => {
                      trip && action && updateTripActionTimes(trip, action, startTime, endTime);
                    }}
                  />
                ),
              });
            },
          });
        }
      }

      if (action.type === 'stop') {
        options.push(
          {
            name: t('action.addWaitAction'),
            onClick: (e) => {
              e.preventDefault();

              const newAction = { ...action };
              const newSubActions = [...newAction.actions];
              newSubActions.push({
                ...new Action('wait'),
                sequenceNr: action.actions.length + 1,
                lifeCycle: 'planned',
              });
              newAction.actions = newSubActions;
              updateTripAction(trip, newAction);
            },
          },
          {
            name: t('action.addBreakAction'),
            onClick: (e) => {
              e.preventDefault();

              const newAction = { ...action };
              const newSubActions = [...newAction.actions];
              newSubActions.push({
                ...new Action('break'),
                sequenceNr: action.actions.length + 1,
                lifeCycle: 'planned',
              });
              newAction.actions = newSubActions;
              updateTripAction(trip, newAction);
            },
          }
        );
      }
    }

    return (
      <>
        <FileViewer
          t={t}
          isActive={filePreviewOpen}
          files={files}
          file={focussedFile}
          onClose={() => {
            this.setState({
              files: [],
              focussedFile: null,
              filePreviewOpen: false,
            });
          }}
        />
        {depth === 0 && actionIndex >= 0 && previousMoveAction && !isGrouped ? (
          <div
            className={`action-card action-card__move--${previousMoveAction.lifeCycle} action-card__move `}
          >
            <div className={`stick${actionIndex === 0 ? ' stick--first' : ''}`}></div>
            {previousMoveAction && (
              <div className="trip">
                <div className="kilometers">
                  {previousMoveAction?.route?.entity?.distance > 0
                    ? (previousMoveAction?.route?.entity?.distance / 1000).toFixed(2) + ' KM'
                    : ''}
                </div>
                <div className="bubble">
                  <ReactSVG
                    className="icon"
                    src={`/icons/transportModes/${previousMoveAction.transportMode || 'road'}.svg`}
                  />
                </div>
                <div className="time">
                  {momentToDuration(
                    moment.duration(previousMoveAction?.route?.entity?.time, 'seconds')
                  )}
                  <MapPeek
                    encodedLineString={previousMoveAction?.route?.entity?.lineString}
                  />
                </div>
              </div>
            )}
          </div>
        ) : actionIndex > 0 && !isGrouped ? (
          <div className="action-card action-card__spacer"></div>
        ) : null}
        <div
          className={`action-card action-card--${action.lifeCycle}${
            depth === 0 ? ' action-card--base' : ' action-card--child'
          } ${isGrouped ? ' grouped' : ''} ${isNextGrouped ? ' addTrack grouped' : ''}`}
          key={`action-${action.id}-${action.lifeCycle}`}
        >
          <div className="flex-container justify-between">
            <div
              className={`action-card__name d-flex align-items-center ${
                isDraggable ? '' : 'gap-20'
              }`}
            >
              <div className={`d-flex align-items-center w-50`}>
                <span className="action-title">
                  <Tag label={t(`actionType.${action.type}`)} />
                </span>
              </div>
              <div
                className={`d-flex w-50 align-items-center`}
                style={depth > 0 ? {} : { marginLeft: '15px' }}
              >
                <Tag label={action.lifeCycle} className={action.lifeCycle}>
                  {action.lifeCycle}
                </Tag>
              </div>
            </div>
            {options.length > 0 && <OptionsMenu className="self-center" items={options} />}
            {depth > 0 && isDraggable && <DragHandle />}
          </div>

          <div className={`card-content`}>
            {depth === 0 && action.location ? (
              <div className="action-card__location card-item">
                <span
                  className="as-link text-underline d-flex gap-15"
                  onClick={(e) => {
                    addToStack({
                      className: 'lightgray',
                      name: action.location.entity.name,
                      component: <LocationContainer locationId={action.location.entity.id} />,
                    });
                  }}
                >
                  <span>{this.getLocationName(action.location.entity)}</span>
                  <MapPeek latLonPointGeoReference={action.location.entity.geoReference} />
                </span>
                {action.location.entity.remark && (
                  <div className="action-card__remark">
                    <b> {t('action.remark')}:</b> {action.location.entity.remark}
                  </div>
                )}
              </div>
            ) : null}
            {action.consignment && (
              <section className="card-item">
                <div key={`action-consignment`} className="action-card__consignment">
                  <b>{t('consignment.header')}1: </b>
                  <span
                    className="as-link text-underline"
                    onClick={(e) => {
                      addToStack({
                        name: action.consignment.entity.name,
                        className: 'lightgray',
                        component: (
                          <ConsignmentDetailContainer
                            notStackedFromTransportOrder={true}
                            consignmentId={action.consignment.entity.id}
                          />
                        ),
                      });
                    }}
                  >
                    {truncate(action.consignment.entity.name || '', 15)}
                  </span>
                </div>
              </section>
            )}
            {(startDateTimeConstraint || endDateTimeConstraint) && (
              <div className="action-card__timewindow card-item">
                <b>{t('action.timewindow.requested')}: </b>
                <span>
                  {startDateTimeConstraint && startDateTimeConstraint.value
                    ? `${defaultDateTimeFormat(
                        startDateTimeConstraint.value.startDateTime,
                        this.props?.dateFormat
                      )} - `
                    : ''}
                </span>
                <span>
                  {endDateTimeConstraint && endDateTimeConstraint.value
                    ? defaultDateTimeFormat(
                        endDateTimeConstraint.value.endDateTime,
                        this.props?.dateFormat
                      )
                    : ''}
                </span>
              </div>
            )}
            {action.goods && (
              <section className="card-item">
                <div key={`action-${action.id}-goods`} className="action-goods">
                  <b>{t('goods.header')}1: </b>
                  <span
                    className="as-link text-underline"
                    onClick={(e) => {
                      addToStack({
                        name: action.goods.entity.name,
                        component: (
                          <GoodsContainer
                            notStackedFromTransportOrder={true}
                            goodsId={action.goods.entity.id}
                          />
                        ),
                      });
                    }}
                  >
                    {truncate(action.goods.entity.name || '', 15)}
                  </span>
                </div>
              </section>
            )}
            {(action.startTime || action.endTime) && (
              <div className="action-card__timewindow action-card__timewindow--realized card-item">
                <b>{t('action.timewindow.realized')}: </b>
                <span
                  className={`action-card__timewindow__time${
                    startDateTimeConstraint && endDateTimeConstraint
                      ? moment(action.startTime).isBetween(
                          moment(startDateTimeConstraint.value.startDateTime),
                          moment(endDateTimeConstraint.value.endDateTime)
                        )
                        ? ' action-card__timewindow__time--inside'
                        : ' action-card__timewindow__time--outside'
                      : ''
                  }`}
                >
                  {action.startTime
                    ? `${t('action.timewindow.start')}: ${defaultDateTimeFormat(
                        action.startTime,
                        this.props?.dateFormat
                      )} - `
                    : ''}
                </span>
                <span
                  className={`action-card__timewindow__time${
                    startDateTimeConstraint && endDateTimeConstraint
                      ? moment(action.endTime).isBetween(
                          moment(startDateTimeConstraint.value.startDateTime),
                          moment(endDateTimeConstraint.value.endDateTime)
                        )
                        ? ' action-card__timewindow__time--inside'
                        : ' action-card__timewindow__time--outside'
                      : ''
                  }`}
                >
                  {action.endTime
                    ? `${t('action.timewindow.end')}: ${defaultDateTimeFormat(
                        action.endTime,
                        this.props?.dateFormat
                      )}`
                    : ''}
                </span>
              </div>
            )}
            {action.remark && (
              <div className="action-card__remark card-item">
                <b> {t('action.remark')}:</b> {action.remark}
              </div>
            )}
            {action.eta && (
              <div className="action-card__eta card-item">
                <b> {t('action.eta')}:</b> {moment(action.eta).format('HH:mm DD/MM/YYYY')}
              </div>
            )}
            {action.etd && (
              <div className="action-card__etd card-item">
                <b>{t('action.etd')}:</b> {moment(action.etd).format('HH:mm DD/MM/YYYY')}
              </div>
            )}
            {action.tar && (
              <div className="action-card__info card-item">
                <b>{t('action.tar')}</b>: {action.tar}
              </div>
            )}
            {action.pin && (
              <div className="action-card__info card-item">
                <b>{t('action.pin')}</b>: {action.pin}
              </div>
            )}
            {action.transportEquipment && (
              <section className="card-item">
                <div
                  key={`action-${action.id}-transportEquipment`}
                  className="action-card__transportEquipment"
                >
                  <b>{t('transportEquipment.header')}1: </b>
                  <span
                    className="as-link text-underline"
                    onClick={(e) => {
                      addToStack({
                        name: action.transportEquipment.entity.name,
                        className: 'lightgray',
                        component: (
                          <TransportEquipmentContainer
                            notStackedFromTransportOrder={true}
                            transportEquipmentId={action.transportEquipment.entity.id}
                          />
                        ),
                      });
                    }}
                  >
                    {truncate(action.transportEquipment.entity.name || '', 15)}
                  </span>
                </div>
              </section>
            )}
            {action.result && (
              <div className="action-result card-item">
                <div className={`action-result__status ${action.result.status}`}>
                  {action.result.status}
                </div>
              </div>
            )}
            {action.result && (
              <div className="action-result card-item">
                <div className={`action-result__remark`}>{action.result.remark}</div>
              </div>
            )}
            {action.result && (
              <div className="action-result card-item">
                <div className={`action-result__reason`}>{action.result.reason}</div>
              </div>
            )}
          </div>

          {action.communicationRequests.length > 0 && (
            <div className="action-card__communicationRequests">
              {[...action.communicationRequests]
                .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
                .map((communicationRequest, index) => (
                  <CommunicationRequest
                    key={`communicationRequest-${communicationRequest.nonce}-${communicationRequest.id}`}
                    communicationRequest={communicationRequest}
                    hidden={!(expandedCommunicationRequests || index < 1)}
                  />
                ))}

              {action.communicationRequests.length > 1 && (
                <div
                  className={`communicationRequest__expand ${
                    expandedCommunicationRequests ? 'communicationRequest__expand--rotated' : ''
                  }`}
                >
                  <ReactSVG
                    src="/icons/chevron-down.svg"
                    className="down-button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      this.setState({
                        expandedCommunicationRequests: !expandedCommunicationRequests,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {sortedSubActions.length > 0 && (
            <ActionCards
              depth={depth + 1}
              parentAction={action}
              actions={[...sortedSubActions]}
              active={active}
              moveActions={moveActions}
              showConsignment={showConsignment}
              trip={trip}
            />
          )}

          {depth > 0 ? (
            <section className="margin-top">
              <SmallAttachments
                {...this.props}
                key={action.documents}
                files={action.documents?.map((document) => {
                  return document.entity.file;
                })}
                onChange={(files) => {
                  const newAction = { ...action };
                  newAction.documents = files.map((file) => {
                    const newFile = { ...file };
                    return new Association('inline', {
                      name: newFile.originalName,
                      mimeType: newFile.mimeType,
                      content: {
                        contentType: 'uri',
                        uri: newFile.url,
                      },
                      file: newFile,
                    });
                  });

                  updateTripAction(trip, newAction);
                }}
              />
            </section>
          ) : action.documents.length > 0 ? (
            <div className="files">
              {action.documents.length > 0 ? (
                action.documents.map((attachment) => (
                  <div
                    key={`attachment-${attachment.id}`}
                    className="file"
                    onClick={() => {
                      this.setState({
                        filePreviewOpen: true,
                        files: action.documents.map((document) => document.entity.file),
                        focussedFile: attachment.entity.file,
                      });
                    }}
                  >
                    <FilePreview
                      file={attachment.entity.file}
                      isDocVerified={attachment.entity?.verified}
                    />
                  </div>
                ))
              ) : (
                <NoData className="left">{t('action.attachments.notFound')}</NoData>
              )}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
export default withTranslation('translation')(ActionCard);
