import React, { Component } from 'react';

import { FeatureGroup, MapContainer, Marker, Polyline } from 'react-leaflet';
import AsyncSelect from 'react-select/async';
import SimpleReactValidator from 'simple-react-validator';

import { latLngBounds } from 'leaflet';
import moment from 'moment';
import PolyUtil from 'polyline-encoded';

import ActionsInput from '@components/action/crud/ActionsInput';
import QuoteLineInput from '@components/administration/quote/crud/QuoteLineInput';
import CapacityContractInput from '@components/capacityContract/CapacityContractInput';
import ConsignmentSelector from '@components/consignment/crud/ConsignmentSelector';
import ExternalAttributesInput from '@components/general/crud/ExternalAttributesInput';
import PricingElementsInput from '@components/pricing/crud/PricingElementsInput';
import DefaultMarker from '@components/ui/marker/default/DefaultMarker';
import { transformVehicleToValue } from '@components/vehicle/VehicleInput';

import Attachments from '@uicomponents/Attachments';
import Loader from '@uicomponents/Loader';
import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';
import TileLayers from '@uiviews/TileLayers';

import * as routeApi from '@api/routeApi';
import Association from '@models/general/Association';

import { getToBePlannedActions } from '@utils/actionUtils';
import { uuidv4 } from '@utils/commonUtils';
import { momentToDuration } from '@utils/dateUtils';
import { activateInputs, deactivateInputs } from '@utils/formUtils';

import TripBreakdown from '../TripBreakdown';
import TripExpenses from '../TripExpenses';

export default class TripForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      type: this.props.formTrip?.type || 'internal',
      actions: this.props.formTrip.actions ? this.props.formTrip.actions : [],
      calculatingRoute: false,
      route: this.props.updating && this.props.formTrip.route ? this.props.formTrip.route : null,
      actionsHaveChanged: false,
    };
  }

  componentDidMount() {
    const { formTrip, changeValue } = this.props;
    if (formTrip?.quote) {
      changeValue('quoteForm', formTrip.quote);
    }
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  calculateRoute = ({ name, vehicle, actions }) => {
    const { route } = this.state;
    const { formTrip, onChange } = this.props;

    this.setState({
      calculatingRoute: true,
    });
    routeApi
      .calculate({
        name,
        vehicle,
        actions,
        route,
      })
      .then((data) => {
        this.setState({
          route: data.route,
          calculatingRoute: false,
          actionsHaveChanged: false,
        });
        const newTrip = { ...formTrip };
        newTrip.route = data.route;
        onChange('formTrip', newTrip);
      })
      .catch((e) => {
        this.setState({
          calculatingRoute: false,
        });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { onSubmit, formTrip, quoteForm } = this.props;
    const newTrip = { ...formTrip };
    newTrip.quote = { ...quoteForm };
    onSubmit && onSubmit(newTrip);
  };

  validateForm = () => {
    const { type } = this.state;
    const { t, simpleForms } = this.props;

    let isValid = true;

    if (type !== 'forwarded') {
      isValid = this.validator.fieldValid(t('route.header')) && isValid;
    } else {
      isValid = this.validator.fieldValid(t('form.label.capacity')) && isValid;
    }
    if (!simpleForms) {
      isValid = this.validator.fieldValid(t('form.label.name')) && isValid;
    }

    return isValid;
  };

  render() {
    const {
      t,
      platform,
      onChange,
      user,
      targetVehicle,
      formTrip,
      searchVehicles,
      simpleForms,
      dateFormat,
      changeValue,
    } = this.props;

    const { type, calculatingRoute, route, actions, actionsHaveChanged } = this.state;

    const locations = actions
      .filter(
        (action) =>
          action.entity.location &&
          action.entity.location.entity &&
          action.entity.location.entity.geoReference
      )
      .map((action) => action.entity.location.entity);

    const bounds = latLngBounds(
      locations && locations.length > 0
        ? locations.map((location) => [location.geoReference.lat, location.geoReference.lon])
        : [[0, 0]]
    );

    return (
      <div
        className="step-form trip-form"
        key={`${formTrip.id}-${formTrip.versionNumber}-${formTrip.nonce}`}
      >
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          {platform?.features &&
            platform?.features.map((feature) => feature.name).includes('capacity') && (
              <div className="tabs">
                <ul>
                  <li
                    id="internal"
                    className={
                      type === 'internal' ? 'active' : formTrip.capacityContract ? 'disabled' : ''
                    }
                    onClick={() => {
                      this.setState({ type: 'internal' });
                    }}
                  >
                    {t('tripType.internal')}
                  </li>
                  <li
                    id="forwarded"
                    className={type === 'forwarded' ? 'active' : formTrip.vehicle ? 'disabled' : ''}
                    onClick={() => {
                      this.setState({ type: 'forwarded' });
                    }}
                  >
                    {t('tripType.forwarded')}
                  </li>
                  <li
                    id="internal_empty"
                    className={
                      type === 'internal_empty' ? 'active' : formTrip.vehicle ? 'disabled' : ''
                    }
                    onClick={() => {
                      this.setState({ type: 'internal_empty' });
                    }}
                  >
                    {t('tripType.internal_empty')}
                  </li>
                </ul>
              </div>
            )}
          <div className="input-container">
            <div className="text-inputs">
              {!simpleForms && (
                <div>
                  <FormInput
                    type="text"
                    label="form.label.name"
                    required={true}
                    value={formTrip.name}
                    onChange={(event) => {
                      const newTrip = { ...formTrip };
                      newTrip.name = event.target.value;

                      onChange('formTrip', newTrip, event);
                    }}
                  />
                  {this.validator.message(t('form.label.name'), formTrip.name, 'required')}
                </div>
              )}
              <FormInput
                label="form.label.startDateTime"
                required={true}
                className="has-value"
                value={moment(formTrip.startDate || moment()).format('YYYY-MM-DDTHH:mm')}
                onFocus={(e) => (e.currentTarget.type = 'datetime-local')}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.currentTarget.type = 'text';
                  }
                }}
                onChange={(e) => {
                  const newTrip = { ...formTrip };
                  newTrip.startDate = e.target.value ? moment(e.target.value) : null;

                  onChange('formTrip', newTrip);
                }}
              />
            </div>
            {type === 'internal' || type === 'internal_empty' ? (
              <>
                {platform?.features?.map((feature) => feature.name)?.includes('financial') && (
                  <div className="input-group">
                    <h3 className="no-margin-top">{t('form.label.pricingElements')}</h3>
                    <div className="pricing-element">
                      <PricingElementsInput
                        key={formTrip.pricingElements}
                        pricingElements={formTrip.pricingElements}
                        onChange={(pricingElements) => {
                          const newTrip = { ...formTrip };
                          newTrip.pricingElements = pricingElements;

                          onChange('formTrip', newTrip);
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="input-group">
                  <CapacityContractInput
                    value={formTrip.capacityContract}
                    onChange={(newCapacityContract) => {
                      const newTrip = { ...formTrip };
                      newTrip.spotQuote = newCapacityContract ? true : false;
                      newTrip.capacityContract = newCapacityContract;

                      onChange('formTrip', newTrip);

                      if (newCapacityContract) {
                        if (formTrip.quote) {
                          changeValue('quoteForm', formTrip.quote);
                        } else {
                          const initialQuoteForm = {
                            name: '',
                            description: '',
                            direction: 'out',
                            dueAt: moment().add(
                              newCapacityContract?.administration?.dueDays || 14,
                              'days'
                            ),
                            targetBusiness: newCapacityContract?.business,
                            administration: newCapacityContract?.administration,
                            currency: newCapacityContract?.currencies?.[0],
                            lines: [
                              {
                                ...{
                                  name: '',
                                  value: {
                                    amount: null,
                                    taxRate: this.props?.variables?.taxRates?.[0],
                                  },
                                  sequenceNr: 0,
                                  quantity: 1,
                                  description: '',
                                  nonce: uuidv4(),
                                  pricingCategory: null,
                                  endDateTime: null,
                                  startDateTime: null,
                                },
                              },
                            ],
                            subTotal: {
                              amount: 0,
                            },
                            total: {
                              amount: 0,
                            },
                            tax: {
                              amount: 0,
                              inclusiveOfTax: false,
                            },
                          };
                          changeValue('quoteForm', initialQuoteForm);
                        }
                      } else {
                        changeValue('quoteForm', null);
                      }
                    }}
                  />
                  {this.validator.message(
                    t('form.label.capacity'),
                    formTrip.capacityContract,
                    'required'
                  )}
                </div>
                <div className="input-group">
                  <div>{t('form.label.spotquote')}</div>
                  <Switch
                    checked={formTrip.spotQuote}
                    onChange={(e, newState) => {
                      const newTrip = { ...formTrip };
                      newTrip.spotQuote = newState;

                      onChange('formTrip', newTrip);
                    }}
                  />
                </div>
                {formTrip?.spotQuote && <QuoteLineInput {...this.props} />}
              </>
            )}
            {formTrip.capacityContract && (
              <>
                {(formTrip.transportMode === 'maritime' ||
                  formTrip.capaciContract?.contract?.modality === 'maritime') && (
                  <div className="input-group">
                    <ExternalAttributesInput
                      disabledAdd={true}
                      externalAttributes={formTrip.externalAttributes}
                      required={['billOfLadingNumber', 'bookingNumber']}
                      onChange={(externalAttributes) => {
                        const newTrip = { ...formTrip };
                        newTrip.externalAttributes = externalAttributes;

                        onChange('formTrip', newTrip);
                      }}
                    />
                  </div>
                )}
                {(formTrip.transportMode === 'road' ||
                  formTrip.capaciContract?.contract?.modality === 'road') && (
                  <div className="input-group">
                    <ExternalAttributesInput
                      disabledAdd={true}
                      externalAttributes={formTrip.externalAttributes}
                      required={['bookingNumber']}
                      onChange={(externalAttributes) => {
                        const newTrip = { ...formTrip };
                        newTrip.externalAttributes = externalAttributes;

                        onChange('formTrip', newTrip);
                      }}
                    />
                  </div>
                )}
                {(formTrip.transportMode === 'air' ||
                  formTrip.capaciContract?.contract?.modality === 'air') && (
                  <div className="input-group">
                    <ExternalAttributesInput
                      disabledAdd={true}
                      externalAttributes={formTrip.externalAttributes}
                      required={[
                        'airlinePrefix',
                        'serialNumber',
                        'carrierCode',
                        'airwayBillNo',
                        'houseAirwayBillNo',
                      ]}
                      onChange={(externalAttributes) => {
                        const newTrip = { ...formTrip };
                        newTrip.externalAttributes = externalAttributes;

                        onChange('formTrip', newTrip);
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {(type === 'internal' ||
              type === 'internal_empty' ||
              formTrip.capacityContract?.capacity?.subType === 'vehicle') && (
              <div className="input-group vehicle">
                <div className="input-group no-margin-top">
                  <AsyncSelect
                    value={
                      formTrip.vehicle
                        ? formTrip.vehicle.entity
                          ? transformVehicleToValue(formTrip.vehicle.entity)
                          : transformVehicleToValue(formTrip.vehicle)
                        : null
                    }
                    isDisabled={targetVehicle ? true : false}
                    noOptionsMessage={() => t('vehicles.notFound')}
                    placeholder={t('form.label.searchVehicle')}
                    loadingMessage={() => t('loading')}
                    loadOptions={(inputValue, callback) => {
                      if (this.searchTimeout) clearTimeout(this.searchTimeout);
                      this.searchTimeout = setTimeout(() => {
                        searchVehicles({ query: inputValue }, 0, 20).then((response) => {
                          callback(
                            response.vehicles.map((vehicle) => transformVehicleToValue(vehicle))
                          );
                        });
                      }, 400);
                    }}
                    onChange={(event) => {
                      const newTrip = { ...formTrip };
                      newTrip.vehicle = event.vehicle;

                      onChange('formTrip', newTrip);
                    }}
                  />
                </div>
              </div>
            )}

            <div className="input-group upload-files">
              <h3 className="no-margin-top no-margin-bottom">{t('form.label.documents')}</h3>
              <Attachments
                {...this.props}
                key={formTrip.documents.map((document) => document.id).join('-')}
                files={formTrip.documents.map((document) => {
                  return document.entity.file;
                })}
                onChange={(files) => {
                  const newTrip = { ...formTrip };
                  newTrip.documents = files.map((file) => {
                    const newFile = { ...file };
                    return new Association('inline', {
                      name: newFile.originalName,
                      mimeType: newFile.mimeType,
                      content: {
                        contentType: 'uri',
                        uri: newFile.url,
                      },
                      file: newFile,
                    });
                  });
                  onChange('formTrip', newTrip);
                }}
              />
            </div>
            <div className="input-group">
              <div>{t('form.label.linkedToPreviousTrip')}</div>
              <Switch
                checked={formTrip.linkedTrip}
                onChange={(e, newState) => {
                  const newTrip = { ...formTrip };
                  newTrip.linkedTrip = newState;

                  onChange('formTrip', newTrip);
                }}
              />
            </div>

            {type !== 'internal_empty' && (
              <div className="consignment-selector">
                <ConsignmentSelector
                  {...this.props}
                  selectedConsignments={formTrip.consignments}
                  onChange={(consignments) => {
                    this.setState(
                      {
                        actions: getToBePlannedActions(consignments),
                      },
                      () => {
                        const newTrip = { ...formTrip };
                        newTrip.actions = this.state.actions;

                        onChange('formTrip', newTrip);
                      }
                    );
                  }}
                />
              </div>
            )}
            {(actions.length > 0 || type === 'internal_empty') && (
              <>
                <div className="input-group">
                  <ActionsInput
                    key={
                      actions
                        ? actions
                            .map((action) => `${action.entity.id}-${action.entity.nonce}`)
                            .join('-')
                        : 'no-actions'
                    }
                    actionTypes={
                      type === 'internal_empty'
                        ? [
                            'genericAction',
                            'load',
                            'unload',
                            'attachTransportEquipment',
                            'detachTransportEquipment',
                            'break',
                            'refuel',
                          ]
                        : [
                            'genericAction',
                            'unload',
                            'attachTransportEquipment',
                            'detachTransportEquipment',
                            'break',
                            'refuel',
                          ]
                    }
                    actions={actions}
                    onChange={(changedActions) => {
                      const newTrip = { ...formTrip };

                      const newActions = [
                        ...changedActions
                          .sort((a, b) => a.entity.sequenceNr - b.entity.sequenceNr)
                          .map((action, index) => {
                            const newAction = { ...action };
                            const entity = { ...newAction.entity };
                            entity.sequenceNr = index;
                            newAction.entity = entity;
                            return newAction;
                          }),
                      ];

                      newTrip.actions = newActions;

                      this.setState({
                        actions: newActions,
                        actionsHaveChanged: true,
                      });

                      onChange('formTrip', newTrip);
                    }}
                  />
                </div>
              </>
            )}
            {calculatingRoute ? (
              <Loader />
            ) : (
              <>
                {actions
                  .map((action) => action.entity)
                  .filter((a) => a && a.location && a.location.entity).length > 1 &&
                formTrip.vehicle ? (
                  <>
                    <hr />
                    <div className="input-group center">
                      <button
                        onClick={(e) => {
                          e.preventDefault();

                          this.calculateRoute({
                            name: formTrip.name,
                            vehicle: formTrip.vehicle,
                            actions: [...this.state.actions],
                          });
                        }}
                      >
                        {formTrip.route ? t('form.recalculateRoute') : t('form.calculateRoute')}
                      </button>
                      {formTrip.route && !actionsHaveChanged ? null : (
                        <div>
                          <i>{t('trip.hasToCalculateRoute')}</i>
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
                {route !== null && (
                  <>
                    <div className="trip-info">
                      <div className="trip-info__distance">
                        <div className="trip-info__label">{t('form.label.distance')}</div>
                        <div>{Math.round(route.distance / 1000)}KM</div>
                      </div>
                      <div className="trip-info__time">
                        <div className="trip-info__label">{t('form.label.duration')}</div>
                        <div>{momentToDuration(moment.duration(route.time, 'seconds'))}</div>
                      </div>
                    </div>
                    <h2>{t('trip.expenses')}</h2>
                    <TripExpenses user={user} expenses={route.expenses} />
                    <TripBreakdown
                      trip={{ id: '', actions: actions, route: route }}
                      dateFormat={dateFormat}
                    />
                    <div className="trip-map">
                      <MapContainer
                        className="trip-map__container"
                        bounds={bounds}
                        preferCanvas={false}
                        zoomControl={false}
                        boundsOptions={{ padding: [35, 35] }}
                      >
                        <TileLayers />
                        <FeatureGroup>
                          {locations.map((location, index) => (
                            <Marker
                              key={`${location.id}-${index}`}
                              icon={DefaultMarker}
                              position={[location.geoReference.lat, location.geoReference.lon]}
                            ></Marker>
                          ))}
                        </FeatureGroup>
                        {route?.lineString
                          ?.split(',')
                          ?.filter((s) => s)
                          ?.map((lineString) => (
                            <Polyline
                              key={route.id}
                              positions={PolyUtil.decode(lineString)}
                              color={'red'}
                            />
                          ))}
                      </MapContainer>
                    </div>
                  </>
                )}
              </>
            )}
            {this.validator.message(t('route.header'), formTrip.route, 'required')}
            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validateForm() || actionsHaveChanged}
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
